import React from 'react';
import styled from 'styled-components';
import scalingSize from '$utils/scalingSize';

const StyledHeading = styled.h2`
  text-align: left;
  justify-self: center;
  align-self: center;
  margin: 0;
  ${scalingSize('font-size', 24, 48)};
  line-height: 1.25;
  letter-spacing: -0.02em;
  grid-column: 1 / -1;
  width: 100%;
`;

const Heading: React.FC = ({ children }) => <StyledHeading>{children}</StyledHeading>;

export default Heading;
