import React, { createRef, useState } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import styled from 'styled-components';
import { WrapSmall } from '$components/Wraps';
import TextInput from '$components/TextInput';
import Checkbox from '$components/Checkbox';
import dynamicPadding2x from '$utils/dynamicPaddingx2';
import Heading from './Heading';
import WebinarFormButton from './WebinarFormButton';
import User from '$assets/icons/user.svg';
import Mail from '$assets/icons/mail.svg';
import Home from '$assets/icons/home.svg';

const Outer = styled.section`
  background-color: var(--color-cloud4);
  ${dynamicPadding2x};
  position: relative;
`;

const Inner = styled(WrapSmall)`
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 100%;
  grid-gap: 1.5em 1.5em;

  @media screen and (min-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const StyledTextInput = styled(TextInput)`
  box-shadow: inset 0 0 0 2px var(--color-slate);
`;

const StyledCheckbox = styled(Checkbox)`
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 480px) and (max-width: 767px) {
    margin-right: 0;
  }
`;

const BlockWebinarForm: React.FC = () => {
  const formRef = createRef<HTMLFormElement>();
  const [formState, setFormState] = useState({
    email: '',
    name: '',
    surname: '',
    office: '',
    webinarTermsAccepted: false,
    mailingList: false,
  });

  const onFormChange = ({ target }) => {
    setFormState({
      ...formState,
      [target.name]: target.type === 'checkbox' ? target.checked : target.value,
    });
  };

  const onSubmit = () => {
    formRef.current.submit();

    setTimeout(() => {
      setFormState({
        email: '',
        name: '',
        surname: '',
        office: '',
        webinarTermsAccepted: false,
        mailingList: false,
      });
    }, 500);
  };

  return (
    <Outer id="webinar-signup">
      <form
        ref={formRef}
        action="https://osynlig.us2.list-manage.com/subscribe/post?u=51f766054e084b015dd7f0252&amp;id=c233f4182b"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
        // React typing seems to be missing https://github.com/microsoft/TypeScript/issues/40940
        rel="noopener"
        onSubmit={onSubmit}
      >
        <Inner>
          <Heading>Anmäl dig här</Heading>
          <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
            <input type="text" name="b_51f766054e084b015dd7f0252_c233f4182b" tabIndex={-1} defaultValue="" />
          </div>
          <StyledTextInput
            onChange={onFormChange}
            Icon={User}
            type="text"
            name="name"
            placeholder="Förnamn"
            required
            value={formState.name}
          />
          <StyledTextInput
            onChange={onFormChange}
            Icon={User}
            type="text"
            name="surname"
            placeholder="Efternamn"
            required
            value={formState.surname}
          />
          <StyledTextInput
            onChange={onFormChange}
            Icon={Mail}
            type="email"
            name="email"
            placeholder="E-post"
            required
            value={formState.email}
          />
          <StyledTextInput
            onChange={onFormChange}
            Icon={Home}
            type="text"
            name="office"
            placeholder="Företag"
            value={formState.office}
          />
          <StyledCheckbox
            onChange={onFormChange}
            checked={formState.mailingList}
            id="mce-group[69118]-69118-0"
            name="mailingList"
          >
            Ja tack! Jag vill ha Osynligs nyhetsbrev
          </StyledCheckbox>
          <StyledCheckbox
            onChange={onFormChange}
            checked={formState.webinarTermsAccepted}
            id="webinarTerms"
            name="webinarTermsAccepted"
            required
          >
            Jag godkänner att ta emot utskick i enlighet med{' '}
            <AniLink cover direction="left" to="/integritetspolicy">
              integritetspolicyn
            </AniLink>
          </StyledCheckbox>
          <WebinarFormButton>Skicka anmälan</WebinarFormButton>
        </Inner>
      </form>
    </Outer>
  );
};

export default BlockWebinarForm;
