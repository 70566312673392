import React from 'react';
import styled from 'styled-components';
import Button from '$components/Button';

const StyledButton = styled(Button)`
  align-self: center;
  justify-self: center;

  @media screen and (min-width: 960px) {
    justify-self: end;
    grid-column: 2;
  }
`;

const WebinarFormButton: React.FC = ({ children }) => <StyledButton>{children}</StyledButton>;

export default WebinarFormButton;
